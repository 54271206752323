<template>
	<section class="section wow">
		<div class="content">
			<div class="error-page-block">
				<div class="error-page-block-content">
					<h3 class="section-title">
						404 <br>
						Страница не найдена
					</h3>
					<div class="text">
						<p>
							Попробуйте обновить страницу позже или вернитесь на
							<router-link to="/">
								главную
							</router-link>
							.
						</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'))
    }
}
</script>
